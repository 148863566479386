<template>
  <div class="lenovo-about-container">
    <div class="about-nav">
      <!-- <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">用户许可协议</el-menu-item>
        <el-menu-item index="2">隐私政策</el-menu-item>
        <el-menu-item index="3">儿童个人信息保护规则</el-menu-item> -->
        <!-- <el-menu-item index="4">联系我们</el-menu-item> -->
      <!-- </el-menu>     -->
      <div class="nav-content">
        <div :class="{'nav-option':true, 'nav-active':index == activeIndex}" v-for="(item,index) in nav" :key="index" @click="clickNav(item,index)">{{item.name}}</div>
      </div>
      <div class="back-btn" @click="clickBackBtn">
        返回
      </div>
    </div>
    <div class="about-body">
      <!-- <div v-if="activeIndex == 0"></div>
      <div v-if="activeIndex == 1"></div>
      <div v-if="activeIndex == 2"></div> -->
      <user-license v-if="activeIndex == 0"></user-license>
      <privacy v-if="activeIndex == 1"></privacy>
      <child-info v-if="activeIndex == 2"></child-info>

      <!-- <iframe v-if="activeIndex == 0" src="https://fordata.forclass.net/lenovo/account/about/userLicense.html" frameborder="0" style="width:100%;display:block;height:100%"></iframe>
      <iframe v-if="activeIndex == 1" src="https://fordata.forclass.net/lenovo/account/about/privacy.html" frameborder="0" style="width:100%;display:block;height:100%"></iframe>
      <iframe v-if="activeIndex == 2" src="https://fordata.forclass.net/lenovo/account/about/childInfo.html" frameborder="0" style="width:100%;display:block;height:100%"></iframe> -->
    </div>
  </div>
</template>
<script>
import childInfo from './ChildInfo.vue'
import userLicense from './UserLicense.vue'
import privacy from './Privacy.vue'
export default {
  components:{childInfo,userLicense,privacy},
  data() {
    return {
      nav:[
        {name:'用户许可协议'},
        {name:'隐私政策'},
        {name:'儿童个人信息保护规则'},
      ],
      activeIndex:'1',    // 当前高亮颜色
    };
  },
  computed: {
    
  },
  watch: {
  },
  methods: {
    clickNav(item,index){
      // console.log("点击的tab",item);
      this.activeIndex = index
    },
    clickBackBtn(){
      // console.log("点击了返回按钮",this.$route.query);
      let query = this.$route.query
      if(query.hasOwnProperty('redirecturi')){
        console.log("返回query")
        location.href = query.redirecturi
      }else{
        window.history.go(-1)
      }
    }
  },
  created() {
    
  },
  mounted(){
    this.activeIndex = 1
  }
};
</script>
<style lang="css">
.el-menu-item.is-active{
  border-bottom: 2xp solid #FF6A00 !important;
} 
</style>
<style lang="scss">
.lenovo-about-container {
  width: 100%;
  height: 100vh;
  // background: antiquewhite;
  display: flex;
  flex-direction: column;
  .about-nav {
    display: flex;
    height: 65px;
    // width: 100%;
    // background: antiquewhite;
    align-items: center;
    justify-content: space-between;
    padding: 23px 32px 0 32px;
    border-bottom: 2px solid #EBEEF5;
    color: #909399;
    .nav-content {
      height: 65px;
      display: flex;
      align-items: center;
      .nav-option {
        margin-right: 56px;
        font-size: 16px;
        cursor: pointer;
        height: 63px;
        display: flex;
        align-items: center;
      }
      .nav-active {
        height: 65px;
        font-size: 18px;
        font-weight: 700;
        color: #F15F00;
        border-bottom: 2px solid #F15F00;
      }
    }
    .back-btn {
      background: #FF6A00;
      color: white;
      width: 60px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .about-body{
    flex: 1;
    padding: 0 0 0 32px;
    width: 95%;
  }
}
</style>
